import React from "react";
import Modal from "react-modal";
// import popupClose from "../assets/images/popup-close.svg";
import popupClose from "../assets/images/profile-card-close.svg";

import "./styles/CustomModal.css";

Modal.setAppElement("#root");

export const CustomModal = ({ ...props }: any): JSX.Element => {
  const tabIndex = 0;
  return (
    <Modal
      isOpen={props.isOpen}
      onRequestClose={() => props.closeAction(false)}
      overlayClassName={{
        base: "overlay-base",
        afterOpen: "overlay-after",
        beforeClose: "overlay-before",
      }}
      className={{
        base: "content-base",
        afterOpen: "content-after roles",
        beforeClose: "content-before",
      }}
      closeTimeoutMS={500}
    >
      <img
        tabIndex={tabIndex}
        src={popupClose}
        className="close cursor-pointer"
        id="focus_popup"
        alt="close"
        onClick={() => props.closeAction(false)}
      />
      <div className="row g-0">
        {props.title && (
          <div
            className="header fw-bold mb-5"
            aria-label="Heading Section"
            tabIndex={tabIndex}
          >
            {props.title}
          </div>
        )}
        {props.content && (
          <div
            aria-label="content Section"
            className="content client_credentials mb-4"
            tabIndex={tabIndex}
          >
            {props.content}
          </div>
        )}
        <div className="popup-btn-block">
          {props.primaryButton}
          {props.secondaryButton}
        </div>
      </div>
    </Modal>
  );
};

export default CustomModal;
