import React from "react";

import "../styles/banner.css";

export interface BannerProps {
  bannerHeight?: "big" | "small" | "medium";
}

const Banner: React.FC<BannerProps> = ({ bannerHeight }) => {
  return <div className={`banner small bg-banner ${bannerHeight}`} />;
};

export default Banner;
