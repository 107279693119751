import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import { store } from "./app/store";
import { Provider } from "react-redux";
import * as serviceWorker from "./serviceWorker";
import { setupInterceptors } from "./global/interceptors";

import "react-datepicker/dist/react-datepicker.css";

import "./styles/custom.css";
import "./index.css";
import "./dist/output.css";
import "../src/styles/theme.css";
import TagManager from "react-gtm-module";
const tagManagerArgs = {
  gtmId: process.env.REACT_APP_GTM_ID,
};

TagManager.initialize(tagManagerArgs);

setupInterceptors(store);

// Ensure that React Modal can focus on the correct app element

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <App />
    </Provider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
