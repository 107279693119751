import React from "react";
import { useHistory, NavLink, useLocation } from "react-router-dom";
import jwt from "jwt-decode";
import { Constants } from "../global/Constants";
import { scopeProperty } from "../global/scopePayload";

import "./styles/Header.css";

const Navbar = () => {
  const history = useHistory();
  const location = useLocation();
  const onNavClick = (view: string) => {
    history.push(`${view}`);
  };

  const jwtDecode: any = jwt(localStorage.getItem("token") || "");
  const shortenRole: Array<string> = jwtDecode.scopePermissions || [];

  const userRoles = Constants.USER_ROLES;
  const isInIframe = window.self !== window.top;
  return (
    <>
      {userRoles && shortenRole ? (
        <nav role="navigation" className="sec-nav-wrap">
          <ul>
            {shortenRole.includes("L1") && (
              <li role="presentation" className="sec-nav-item">
                <NavLink to="/trust" activeClassName="activeMenu">
                  Trust management
                </NavLink>
              </li>
            )}
            {shortenRole.includes(scopeProperty.ALL_ACCESS) &&
              !Object.keys(jwtDecode).includes("maiaScope") && (
                <>
                  <li role="presentation" className="sec-nav-item">
                    <NavLink to="/systemAdmin" activeClassName="activeMenu">
                      Super Admin management
                    </NavLink>
                  </li>
                  <li role="presentation" className="sec-nav-item">
                    <NavLink to="/roles" activeClassName="activeMenu">
                      Roles
                    </NavLink>
                  </li>
                </>
              )}
            {shortenRole.includes(scopeProperty.USER_CREATE) && (
              <li role="presentation" className="sec-nav-item">
                <NavLink to="/user-management" activeClassName="activeMenu">
                  User management
                </NavLink>
              </li>
            )}
            {shortenRole.includes(scopeProperty.RESOURCE_VIEW) && (
              <li role="presentation" className="sec-nav-item">
                <NavLink
                  exact
                  to="/resource"
                  isActive={() =>
                    location.pathname === "/resource" ||
                    location.pathname === "/resource/create"
                  }
                  activeClassName="activeMenu"
                >
                  {isInIframe ? 'Resources overview' : 'Current resource list'}
                </NavLink>
              </li>
            )}
            {/* {shortenRole.includes(scopeProperty.RESOURCE_CREATE) && (
              <li role="presentation" className="sec-nav-item">
                <NavLink
                  exact
                  to="/resource/create"
                  activeClassName="activeMenu"
                >
                  Create new resource
                </NavLink>
              </li>
            )} */}
            {!isInIframe && (
              <>
                <li role="presentation" className="sec-nav-item">
                  <NavLink
                    to="/notification-centre"
                    activeClassName="activeMenu"
                  >
                    My notifications
                  </NavLink>
                </li>
                {(shortenRole.includes(scopeProperty.RESOURCE_PUBLISHED_VIEW) ||
                  (shortenRole.includes(scopeProperty.ALL_ACCESS) &&
                    Object.keys(jwtDecode).includes("maiaScope"))) && (
                  <li role="presentation" className="sec-nav-item">
                    <NavLink
                      to="/resource-library"
                      activeClassName="activeMenu"
                    >
                      Resource library
                    </NavLink>
                  </li>
                )}
              </>
            )}
          </ul>
        </nav>
      ) : null}
    </>
  );
};

export default Navbar;
