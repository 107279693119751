import React from "react";
import { getRandomText } from "../global/HelperFunctions";
import SearchIcon from "@material-ui/icons/Search";

interface SearchProps {
  handleSearchChange: ({
    target: { value },
  }: React.ChangeEvent<HTMLInputElement>) => void;
  id?: any;
}

export const Search: React.FC<SearchProps> = ({
  handleSearchChange,
  id = getRandomText(10),
}) => {
  return (
    <>
      <div className="input-group">
        <label htmlFor={id} className="sr-only"></label>
        <input
          id={id}
          placeholder="Search"
          onChange={handleSearchChange}
          type="text"
        />
        <i className="m-icon">
          <SearchIcon />
        </i>
      </div>
    </>
  );
};
