import React, { useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import jwt from "jwt-decode";
import { useAppDispatch, useAppSelector } from "../app/hooks";
import { logOutUser, userSelector } from "../features/Auth/AuthSlice";
import { Constants } from "../global/Constants";
import { scopeProperty } from "../global/scopePayload";
import "./styles/Header.css";
import Banner from "./banner/Banner";
import LazyBackgroundImage from "./lazyLoad-image/LazyBackgroundImage";
import HeaderImg from "../assets/images/Maia_header.webp";
import Navbar from "./Navbar";
import { Controller } from "../appcontroller/appcontrol";
import { Avatar } from "./Avatar";
import { ReactComponent as Logo } from "../assets/images/maia-text.svg";

const Header = () => {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const [headerClick, setHeaderClick] = useState(false);

  const onNavClick = (view: string) => {
    history.push(`${view}`);
  };

  const jwtDecode: any = jwt(localStorage.getItem("token") || "");
  const shortenRole: Array<string> = jwtDecode.scopePermissions || [];
  const username: any = `${jwtDecode.name || ""} ${jwtDecode.lastName || ""}`;
  const local_name = localStorage.getItem("username") || "";

  const userRoles = Constants.USER_ROLES;

  const logout = async () => {
    dispatch(logOutUser());
    localStorage.clear();
    window.location.href = `${process.env.REACT_APP_MAIA_PORTAL_URL}/logout`;
  };

  const location = useLocation();

  const getDashboardLink = () => {
    if (
      shortenRole.includes(scopeProperty.ALL_ACCESS) &&
      Object.keys(jwtDecode).includes("maiaScope")
    ) {
      return `${process.env.REACT_APP_MAIA_PORTAL_URL}/rbac/trust/overview/`;
    }
    return `${process.env.REACT_APP_MAIA_PORTAL_URL}/dashboard/`;
  };

  const [isOpen, setIsOpen] = useState(false);

  const toggleDropdown = () => setIsOpen(!isOpen);

  const backgroundImageUrl = HeaderImg;
  const altText = "header design pattern";
  const isInIframe = window.self !== window.top;
  return (
    <>
      {!isInIframe && (
        <>
          {Controller.isBannerVisible && (
            <header className="banner-blk">
              <Banner
                bannerHeight={location.pathname === "/" ? "big" : "small"}
              />
            </header>
          )}
          <div className="bg-header">
            <LazyBackgroundImage
              imageUrl={backgroundImageUrl}
              altText={altText}
            >
              <header className="header-nav-blk top-nav-block">
                <div className="nav-group profile-div">
                  <div className="just-w client-logo flex items-center">
                    <>
                      <Logo />
                    </>
                  </div>
                  <div className="pri-nav-wrap">
                    <ul>
                      <li>
                        <a className="pri-nav-link" href={getDashboardLink()}>
                          Dashboard
                        </a>
                      </li>
                    </ul>
                  </div>

                  <div className="flex items-center gap-4 lg:gap-10 just-w justify-end">
                    <div className="dropdown-group profile">
                      <button
                        className="dropdown-toggle acc-icon"
                        onClick={toggleDropdown}
                      >
                        <svg
                          width="auto"
                          height="auto"
                          viewBox="0 0 37 36"
                          fill="none"
                          className="profileNav"
                        >
                          <path
                            d="M9.24228 26.0248C10.7423 25.0748 12.2548 24.3623 13.7798 23.8873C15.3048 23.4123 16.9423 23.1748 18.6923 23.1748C20.4423 23.1748 22.0985 23.4248 23.661 23.9248C25.2235 24.4248 26.7423 25.1248 28.2173 26.0248C29.2423 24.6748 29.9735 23.3686 30.411 22.1061C30.8485 20.8436 31.0673 19.4748 31.0673 17.9998C31.0673 14.4998 29.8798 11.5623 27.5048 9.1873C25.1298 6.8123 22.1923 5.6248 18.6923 5.6248C15.1923 5.6248 12.2548 6.8123 9.87978 9.1873C7.50478 11.5623 6.31729 14.4998 6.31729 17.9998C6.31729 19.4998 6.53604 20.8686 6.97353 22.1061C7.41103 23.3436 8.16728 24.6498 9.24228 26.0248ZM18.6923 19.2748C17.2173 19.2748 15.9735 18.7686 14.961 17.7561C13.9485 16.7436 13.4423 15.4998 13.4423 14.0248C13.4423 12.5748 13.9485 11.3373 14.961 10.3123C15.9735 9.2873 17.2173 8.7748 18.6923 8.7748C20.1673 8.7748 21.411 9.2873 22.4235 10.3123C23.436 11.3373 23.9423 12.5873 23.9423 14.0623C23.9423 15.5123 23.436 16.7436 22.4235 17.7561C21.411 18.7686 20.1673 19.2748 18.6923 19.2748V19.2748ZM18.6548 33.7873C16.5048 33.7873 14.4735 33.3748 12.561 32.5498C10.6485 31.7248 8.96729 30.5873 7.51729 29.1373C6.06729 27.6873 4.93603 26.0186 4.12353 24.1311C3.31103 22.2436 2.90479 20.1998 2.90479 17.9998C2.90479 15.8248 3.31728 13.7811 4.14228 11.8686C4.96728 9.95605 6.10478 8.28105 7.55478 6.84355C9.00478 5.40605 10.6735 4.26855 12.561 3.43105C14.4485 2.59355 16.5048 2.1748 18.7298 2.1748C20.9048 2.1748 22.9423 2.59355 24.8423 3.43105C26.7423 4.26855 28.411 5.40605 29.8485 6.84355C31.286 8.28105 32.4235 9.95605 33.261 11.8686C34.0985 13.7811 34.5173 15.8248 34.5173 17.9998C34.5173 20.1998 34.0985 22.2436 33.261 24.1311C32.4235 26.0186 31.286 27.6873 29.8485 29.1373C28.411 30.5873 26.736 31.7248 24.8235 32.5498C22.911 33.3748 20.8548 33.7873 18.6548 33.7873V33.7873Z"
                            fill="currentColor"
                          />
                        </svg>
                        <span className="sr-only">profile</span>
                      </button>
                      {isOpen && (
                        <ul className="dropdown-menu">
                          <li className="u_name text-lg">
                            {username
                              ? username.charAt(0).toUpperCase() +
                                username.slice(1)
                              : local_name.charAt(0).toUpperCase() +
                                local_name.slice(1)}
                          </li>
                          <li>
                            {shortenRole[0] !== "SA" && (
                              <a
                                href="/profile"
                                className="block px-4 py-2 no-underline"
                              >
                                Account
                              </a>
                            )}
                          </li>
                          <li>
                            <button
                              onClick={logout}
                              className="block w-full text-left px-4 py-2 text-lg"
                            >
                              Logout
                            </button>
                          </li>
                        </ul>
                      )}
                    </div>
                  </div>
                </div>
                <div className="header-text">
                  <h2>Resources</h2>
                  <p>
                    Update your resource information, manage your users and
                    resource library
                  </p>
                </div>
              </header>
            </LazyBackgroundImage>
          </div>
        </>
      )}

      <>
        <Navbar />
      </>
    </>
  );
};

export default Header;
