import React from "react";
import ReactSelect from "react-select";
import { Controller } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import { FormErrorMessage } from "./FormErrorMessage";

import { getRandomText } from "../global/HelperFunctions";

export const FormSelect = ({
  name,
  register,
  rules,
  errors,
  className,
  label,
  field,
  options,
  valueKey,
  labelKey,
  control,
  linkText,
  isSearchable,
  id = getRandomText(10),
  selectedValue,
  ...props
}: any): JSX.Element => {
  const tabIndex = 0;

  const customStyles = {
    control: (base: any, state: any) => ({
      ...base,
      minHeight: "48px",
      borderColor: "rgba(var(--outline) / 1) !important",
      border: "1px solid",
      boxShadow: state.isFocused ? "0 0 0 2px rgba(var(--primary), 1)" : "none", // Add focus effect
      cursor: "pointer",
      borderRadius: "8px", // Adjust border radius
      backgroundColor: state.isFocused ? "rgba(var(--primary), 0.1)" : "white", // Change background on focus
      "&:hover": {
        borderColor: "rgba(var(--primary) / 1)", // Customize border color on hover
      },
    }),
    placeholder: (base: any, state: any) => ({
      ...base,
      color: "#6c757d",
      fontSize: "16px",
      padding: "0",
    }),
    dropdownIndicator: (base: any, state: any) => ({
      ...base,
      color: "rgba(var(--baseColor) / 1)",
      paddingRight: "16px",
    }),
    indicatorSeparator: (base: any, state: any) => ({
      ...base,
      background: "none",
    }),
    valueContainer: (base: any, state: any) => ({
      ...base,
      paddingLeft: "12px",
      padding: "0",
    }),
    input: (base: any) => ({
      ...base,
      fontSize: "14px",
      padding: "0px",
      margin: "0",
    }),
    menu: (base) => ({
      ...base,
      borderRadius: 0,
      zIndex: "9999",
    }),
    menuList: (base) => ({
      ...base,
      padding: 0,
      overflowY: "auto",
      overflowX: "hidden",
      backgroundColor: "rgba(var(--primary) / 0.2) !important",
      zIndex: "9999",
    }),
    option: (base) => ({
      ...base,
      textOverflow: "ellipsis",
      overflow: "hidden",
      backgroundColor: "rgba(var(--neutral) / 1) !important",
      color: "rgba(var(--baseColor) / 1) !important",
      zIndex: "9999",
      "&:hover": {
        color: "rgba(var(--baseColor) / 1) !important",
        backgroundColor: "rgba(var(--primary) / 0.2) !important",
      },
    }),
  };

  return (
    <div className="input-group">
      <label htmlFor={id}>{label}</label>

      {/* @ts-ignore */}
      <Controller
        name={name}
        render={({ field: { onChange, onBlur, value, name, ref } }) => {
          return (
            <ReactSelect
              // menuIsOpen
              isSearchable={isSearchable}
              inputId={id}
              styles={customStyles}
              value={options.find((c: any) => c[valueKey] === value)}
              getOptionLabel={(option: any) => `${option[labelKey]}`}
              getOptionValue={(option: any) => `${option[valueKey]}`}
              defaultValue={options.find(
                (c: any) => c[valueKey] === selectedValue
              )}
              onChange={(val: any) => {
                console.log("🚀 ~ file: FormSelect.tsx:111 ~ val:", val);
                console.log(val[valueKey]);
                onChange(val[valueKey]);
                if (props.onSelectChange) {
                  props.onSelectChange(val[valueKey], val);
                }
              }}
              ref={ref}
              options={options || []}
              {...props}
            />
          );
        }}
        control={control}
        rules={rules}
      />
      <ErrorMessage
        errors={errors}
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        name={name as any}
        render={({ message }) => (
          <FormErrorMessage className="mt-1">{message}</FormErrorMessage>
        )}
      />

      {/* <FormErrorMessage className="mt-1">Error</FormErrorMessage> */}
      <div className="input-link">{linkText}</div>
    </div>
  );
};
