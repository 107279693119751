import React, { useState, useEffect, forwardRef } from "react";
import { useForm, Controller } from "react-hook-form";
import { FormInput } from "../../components/FormInput";
import DatePicker from "react-datepicker";
import eyeIconBefore from "../../assets/images/eye-icon-before.svg";
import eyeIconAfter from "../../assets/images/eye-icon-after.svg";
import { CustomModal } from "../../components/CustomModal";
import { getUserProfile, updateUser } from "../../features/User/UserSlice";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import "react-datepicker/dist/react-datepicker.css";
import calendarIcon from "../../assets/images/calendar-icon.svg";
import toast from "react-hot-toast";
import Toast from "../../components/Toast";
import { Constants } from "./../../global/Constants";
import { useHistory } from "react-router-dom";

import "./Profile.css";
import moment from "moment";
import { log } from "console";
import { constants } from "buffer";
import jwt from "jwt-decode";
import helperFunctions from "../../global/HelperService";

interface ProfileProps {}

const regex =
  /^(?!.*\s)(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*[~`!@#$%^&*()--+={}\[\]|\\:;"'<>,.?/_₹]).{8,}$/;

const emailregex =
  /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;

export const emailPattern = {
  value: new RegExp(emailregex),
  message: "Enter a valid email address.",
};

export const passworfPattern = {
  value: new RegExp(regex),
  message:
    "Your password must include at least 8 characters with one uppercase, one lowercase, one number, one special character.",
};

export const Profile: React.FC<ProfileProps> = () => {
  const history = useHistory();
  const dispatch = useAppDispatch();
  const [userProfile, setUserProfile] = useState({} as any);
  const jwtDecode: any = jwt(localStorage.getItem("token") || "");
  const shortenRoles = jwtDecode?.roles || [];
  if (!localStorage.getItem("token")) {
    history.push("/login");
  }
  useEffect(() => {
    dispatch(getUserProfile({}))
      .then((b) => {
        b.payload.data.dateOfBirth = b.payload.data.dateOfBirth
          ? moment(b.payload.data.dateOfBirth).format("DD-MM-YYYY")
          : "";
        setUserProfile(b.payload.data);
        setValue("firstname", b.payload.data.name, { shouldTouch: true });
        setValue("lastname", b.payload.data.lastName, { shouldTouch: true });
      })
      .catch((e) => {
        setUserProfile({});
      });
  }, []);

  const {
    register,
    handleSubmit,
    reset,
    control,
    getValues,
    setValue,
    formState: { errors, isDirty, isValid },
  } = useForm({ mode: "all", reValidateMode: "onChange" });

  const {
    register: register1,
    handleSubmit: handleSubmit1,
    reset: reset1,
    getValues: getValues1,
    setValue: setValue1,
    formState: { errors: errors1, isDirty: isDirty1, isValid: isValid1 },
  } = useForm({ mode: "all", reValidateMode: "onChange" });

  const onSubmit = (data: any) => {
    const payload = {
      name: getValues("firstname").trim(),
      lastName: getValues("lastname").trim(),
    };
    dispatch(updateUser({ userId: userProfile._id, updatedData: payload }))
      .then((s) => {
        // toast.custom((t) => (
        //   <Toast
        //     id={t.id}
        //     title="SUCCESS"
        //     content={Constants.USER_PROFILE_UPDATE_SUCCESS}
        //   />
        // ));
        helperFunctions.showToast(
          `${Constants.USER_PROFILE_UPDATE_SUCCESS}`,
          "success"
        );

        window.location.reload();
      })
      .catch((e) => {
        // toast.custom((t) => (
        //   <Toast
        //     id={t.id}
        //     title="ERROR"
        //     content={Constants.USER_PROFILE_UPDATE_FAILED}
        //   />
        // ));
        helperFunctions.showToast(
          `${Constants.USER_PROFILE_UPDATE_FAILED}`,
          "error"
        );
      });
  };

  const onSubmitPassword = (data: any) => {
    const payload = {
      newPassword: getValues1("newpassword"),
      confirmPassword: getValues1("connewpassword"),
    };
    if (payload.newPassword !== payload.confirmPassword) {
      // toast.custom((t) => (
      //   <Toast
      //     id={t.id}
      //     title="ERROR"
      //     content={Constants.USER_PASSWORD_MISMATCH}
      //   />
      // ));
      helperFunctions.showToast(`${Constants.USER_PASSWORD_MISMATCH}`, "error");
    } else {
      setValue1("newpassword", "", {});
      setValue1("connewpassword", "", {});
      dispatch(
        updateUser({
          userId: userProfile._id,
          updatedData: { password: payload.newPassword },
        })
      )
        .then((s) => {
          // toast.custom((t) => (
          //   <Toast
          //     id={t.id}
          //     title="SUCCESS"
          //     content={Constants.USER_PASSWORD_UPDATE_SUCCESS}
          //   />
          // ));
          helperFunctions.showToast(
            `${Constants.USER_PASSWORD_UPDATE_SUCCESS}`,
            "success"
          );
          onchangepassword();
        })
        .catch((e) => {
          // toast.custom((t) => (
          //   <Toast
          //     id={t.id}
          //     title="ERROR"
          //     content={Constants.USER_PASSWORD_UPDATE_FAILED}
          //   />
          // ));
          helperFunctions.showToast(
            `${Constants.USER_PASSWORD_UPDATE_FAILED}`,
            "error"
          );
          onchangepassword();
        });
    }
  };

  const CustomDatePicker = forwardRef(({ value, onClick }: any, ref: any) => (
    <div
      className="custom-date-picker cursor-pointer flex justify-center items-center w-100 p-2"
      onClick={onClick}
      ref={ref}
    >
      <span className="ms-2">{value}</span>
      <img src={calendarIcon} alt="calendar" />
    </div>
  ));

  const [conpasswordonClick, setConPasswordonClick] = useState<Boolean>(false);
  const [newpasswordonClick, setNewPasswordonClick] = useState<Boolean>(false);
  const [ischangeModal, setchangeModal] = useState(false);

  const iconOnClick = () => {
    setConPasswordonClick(!conpasswordonClick);
  };
  const iconNewPassOnClick = () => {
    setNewPasswordonClick(!newpasswordonClick);
  };

  const onchangepassword = () => {
    setchangeModal(false);
    setConPasswordonClick(false);
    setNewPasswordonClick(false);
    reset1();
  };

  const deleteTrustModal = {
    isOpen: ischangeModal,
    title: "Change Password",
    content: (
      <>
        <div className="row">
          <div className="col-sm-12" style={{ marginBottom: "40px" }}>
            <form
              // onKeyPress={(e) => {
              //   if (e.key === "Enter") {
              //     e.preventDefault();
              //   }
              // }}
              action=""
              onSubmit={handleSubmit1(onSubmitPassword)}
            >
              <FormInput
                id="new-password"
                type="Password"
                name="newpassword"
                label="New password"
                placeholder="Enter a new password"
                register={register1}
                iscommon
                frmProfilePasswordPage={true}
                Onclick={() => setchangeModal(true)}
                rules={{
                  required: true,
                  pattern: passworfPattern,
                }}
                eyeIconBefore={eyeIconAfter}
                eyeIconAfter={eyeIconBefore}
                iconOnClick={iconNewPassOnClick}
                eyeIconClick={newpasswordonClick}
                errors={errors1}
              />
              <FormInput
                id="con-new-psw"
                type="Password"
                name="connewpassword"
                label="Confirm new password"
                placeholder="Enter a confirm password"
                register={register1}
                iscommon
                frmProfilePasswordPage={true}
                Onclick={() => setchangeModal(true)}
                rules={{
                  required: true,
                  pattern: passworfPattern,
                  validate: (value) =>
                    value !== getValues1().newpassword
                      ? "Password does not match"
                      : null,
                }}
                eyeIconBefore={eyeIconAfter}
                eyeIconAfter={eyeIconBefore}
                iconOnClick={iconOnClick}
                eyeIconClick={conpasswordonClick}
                errors={errors1}
              />
              <div className="row">
                <div className="col-sm-12">
                  <button className="btn btn-secondary" disabled={!isValid1}>
                    SAVE CHANGES
                  </button>
                </div>
              </div>
              <div className="row">
                <div
                  className="col-sm-12"
                  style={{
                    marginTop: "40px",
                    fontSize: "12pt",
                    fontWeight: "bolder",
                  }}
                >
                  <span
                    className="blue-link"
                    onClick={() => onchangepassword()}
                  >
                    CANCEL
                  </span>
                </div>
              </div>
            </form>
          </div>
        </div>
      </>
    ),
    closeAction: onchangepassword,
  };

  return (
    <div className="profile-section">
      <div className="profile-block">
        <h1>Profile</h1>
        <p>On this page, you can update your personal details</p>
        <form action="" onSubmit={handleSubmit(onSubmit)}>
          <FormInput
            id="firstname"
            type="text"
            name="firstname"
            label="First name"
            placeholder="Enter your first name"
            iscommon
            register={register}
            rules={{
              required: "This field is required",
              pattern: {
                value: /^(?=\s*\S).{1,50}$/g,
                message: "This field is required",
              },
            }}
            errors={errors}
            disabled={true}
          />
          <FormInput
            id="lastname"
            type="text"
            name="lastname"
            label="Last name"
            placeholder="Enter your last name"
            register={register}
            iscommon
            rules={{
              required: "This field is required",
              pattern: {
                value: /^(?=\s*\S).{1,50}$/g,
                message: "This field is required",
              },
            }}
            errors={errors}
            disabled={true}
          />
          {/* <FormInput
            id="dob"
            type="text"
            name="dob"
            label="Date of birth"
            placeholder="Enter your date of birth"
            value={userProfile.dateOfBirth ? userProfile.dateOfBirth : ''}
            register={register}
            onChange={(e:any) => setUserProfile({...userProfile, dateOfBirth:e.target.value})}
            iscommon
            rules={{
              required: "This field is required",
            }}
            errors={errors}
          /> */}
          <FormInput
            id="email"
            type="text"
            name="email"
            label="Email"
            placeholder="Enter your Email"
            value={userProfile.email}
            iscommon
            // register={register}
            // rules={{
            //   required: "This field is required",
            //   pattern: emailPattern,
            // }}
            errors={errors}
            disabled={true}
          />
          {/* {!shortenRoles.includes("SA") && (
            <FormInput
              id="password"
              type="Password"
              name="password"
              label="Password"
              placeholder="Add a Password"
              value="password"
              iscommon
              // register={register}
              changepassword
              Onclick={() => setchangeModal(true)}
              errors={errors}
              disabled={true}
            />
          )} */}

          {/* <div className="text-center">
            <button className="btn btn-primary" disabled={!isDirty || !isValid}>
              SAVE CHANGES
            </button>
          </div> */}
        </form>
      </div>
      <CustomModal {...deleteTrustModal} />
    </div>
  );
};
